import { JailCardStore } from '@stores/JailCardStore';

interface JailCardView {
  jailCards: number;
}

export class AdminJailCardViewCreator {
  constructor(private readonly jailCardStore: JailCardStore = new JailCardStore()) {}

  public async CreateView(cohortUserId: number): Promise<JailCardView> {
    // Get the content from the API
    const jailCards = await this.jailCardStore.AdminReadByCohortUserId(cohortUserId);

    // Return the view
    return {
      jailCards: jailCards,
    };
  }
}
