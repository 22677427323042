import React, { useEffect, useState } from 'react';
import { GoalSummaryView, Lookup, ParticipantView, RawGoal } from '@views';
import { Box, Button, Fab, Grid2, MenuItem, Select, Typography } from '@mui/material';
import { palette } from '@app/palette';
import { AdminGoalCardViewCreator } from '@viewCreators/AdminGoalCardViewCreator';
import logger from '@utils/logger';
import { ActivityLookupViewCreator } from '@viewCreators';
import { ApiResponse, GoalStore } from '@stores';
import { ExistingGoal } from '@views/ExistingGoal';
import { ToDate } from '@utils/DateUtils';
import { DataGrid, GridRowId } from '@mui/x-data-grid';
import { DebugPanel } from '@components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import { GoalCompletionStatus } from '@views/goalCompletionStatus';

interface CohortUserGoalEditorProps {
  cohortUser: ParticipantView;
}

export const CohortUserGoalEditor: React.FC<CohortUserGoalEditorProps> = ({ cohortUser }) => {
  const goalStore = new GoalStore();
  const [goals, setGoals] = useState<{
    goals: GoalSummaryView[];
    progress: GoalCompletionStatus;
  }>({
    goals: [],
    progress: {},
  });

  // TODO: Add activity administration functionality
  const [activities, setActivities] = useState<Lookup[]>([]);

  // On mount (or when the cohortUser changes) fetch activities and goals.
  useEffect(() => {
    const initializeGoalState = async () => {
      await fetchActivities().catch((err) => logger.error(err));
      await loadGoalCardView();
    };

    initializeGoalState();
  }, [cohortUser]);

  const fetchActivities = async () => {
    const view = await new ActivityLookupViewCreator().createView();
    setActivities(view.activities);
  };

  const loadGoalCardView = async () => {
    const view = await new AdminGoalCardViewCreator().CreateView(cohortUser.id);
    setGoals(view.goalsWithProgress);
  };

  const handleSave = async (
    event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
    goalId: number
  ) => {
    event.preventDefault();
    const goalToSave = goals.goals.find((goal) => goal.id === goalId);

    if (!goalToSave) {
      logger.error('Goal not found');
      return;
    }

    const formattedGoal: ExistingGoal = {
      id: goalToSave.id,
      activityId: goalToSave.activity,
      name: `I commit to ${
        activities.find((activity) => activity.id === goalToSave.activity)?.name || 'Unknown Activity'
      } ${goalToSave.frequency} Times per Week for ${goalToSave.duration} Minutes`,
      duration: goalToSave.duration,
      cohortUserId: cohortUser.id,
      frequency: goalToSave.frequency,
      timeframeId: 1,
      endDate: goalToSave.endDate,
      startDate: goalToSave.startDate,
    };

    try {
      const response: ApiResponse = await goalStore.UpdateGoal(formattedGoal);
      if (response.status === 'SUCCESS') {
        window.alert('Goal saved successfully');
        await loadGoalCardView();
      } else {
        window.alert('Failed to save goal');
        logger.error('Failed to save goal:', response);
      }
    } catch (error) {
      window.alert('Failed to save goal');
      logger.error('Error saving goal:', error);
    }
  };

  const handleDelete = async (
    event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
    goalId: number
  ) => {
    event.preventDefault();
    if (!window.confirm('Are you sure you want to delete this goal?')) {
      return;
    }
    try {
      const response: ApiResponse = await goalStore.DeleteGoal(goalId);
      if (response.status === 'SUCCESS') {
        window.alert('Goal deleted successfully');
        await loadGoalCardView();
      } else {
        window.alert('Failed to delete goal');
        logger.error('Failed to delete goal:', response);
      }
    } catch (error) {
      window.alert('Failed to delete goal');
      logger.error('Error deleting goal:', error);
    }
  };

  const handleCreateGoal = async (event: any, newGoalIndex: number) => {
    event.preventDefault();
    const newGoal = goals.goals[newGoalIndex];
    const formattedGoals: RawGoal[] = [
      {
        activityId: newGoal.activity,
        name: newGoal.goalText,
        duration: newGoal.duration,
        durationTimeframeId: 1,
        cohortUserId: cohortUser.id,
        frequency: newGoal.frequency,
        timeframeId: 1,
        endDate: ToDate(newGoal.endDate),
        startDate: ToDate(newGoal.startDate),
      },
    ];

    try {
      const response: ApiResponse = await goalStore.Write(formattedGoals);
      if (response.status === 'SUCCESS') {
        window.alert('Goal created successfully');
        await loadGoalCardView();
      } else {
        window.alert('Failed to create goal');
        logger.error('Failed to create goal:', response);
      }
    } catch (error) {
      window.alert('Failed to create goal');
      logger.error('Error creating goal:', error);
    }
  };

  const handleCancelNewGoal = (index: number) => {
    const newGoals = goals.goals.filter((_, i) => i !== index);
    setGoals({ ...goals, goals: newGoals });
  };

  const handleAddGoalRow = () => {
    // If there is already a new goal row, don't add another.
    if (goals.goals.find((goal) => goal.id === 0)) {
      window.alert('Please save or cancel the current goal before adding another');
      return;
    }
    const newGoal: GoalSummaryView = {
      id: 0,
      goalText: '',
      activity: activities.length > 0 ? activities[0].id : 0,
      duration: 0,
      frequency: 0,
      startDate: '',
      endDate: '',
      timeframe: 1,
      goalsComplete: 0,
    };
    setGoals({
      ...goals,
      goals: [...goals.goals, newGoal],
    });
  };

  const getGoalText = (item: GoalSummaryView) => {
    const goal = item as GoalSummaryView;
    if (!goal) return '';
    const selectedActivity =
      activities.find((activity) => {
        return activity.id === goal.activity;
      })?.name || 'Unknown Activity';
    return `I commit to ${selectedActivity} ${goal.frequency} Times per Week for ${goal.duration} Minutes`;
  };

  return (
    <Box
      borderRadius={8}
      border={1}
      borderColor={palette.background.border}
      display="block"
      position="relative"
      padding={4}
      margin={4}
    >
      <DebugPanel displayItem={goals.goals}></DebugPanel>
      <Typography
        variant="subtitle1"
        sx={{
          backgroundColor: palette.background.paper,
          position: 'absolute',
          top: -32,
          left: 48,
          padding: 2,
        }}
      >
        Goals
      </Typography>
      <DataGrid
        sx={{ flexGrow: '1' }}
        processRowUpdate={(newGoal, oldItem, params: { rowId: GridRowId }) => {
          const newGoals = [...goals.goals];
          const updatedGoal = {
            ...newGoal,
            startDate: newGoal.startDate || oldItem.startDate,
            endDate: newGoal.endDate || oldItem.endDate,
          };
          const goalText = getGoalText(updatedGoal);
          const idx = newGoals.findIndex((x) => x.id === Number(params.rowId));
          newGoals[idx] = { ...updatedGoal, goalText: goalText };

          setGoals({ ...goals, goals: [...newGoals] });
          return updatedGoal;
        }}
        disableColumnMenu={true}
        slotProps={{
          columnHeaders: { style: { fontFamily: 'roboto' } },
          cell: { style: { fontFamily: 'roboto' } },
          noRowsOverlay: { style: { fontFamily: 'roboto' } },
        }}
        rows={goals.goals}
        hideFooter={true}
        columns={[
          {
            field: 'goalText',
            headerName: 'Goal',
            sortable: false,
            editable: false,
            flex: 1,
            renderCell: (item) => {
              return <Typography flexWrap={'wrap'}>{getGoalText(item.row)}</Typography>;
            },
          },
          {
            field: 'activity',
            headerName: 'Activity',
            minWidth: 250,
            sortable: false,
            editable: true,
            renderCell: (item) => {
              return (
                <Select
                  value={item.row.activity}
                  label="Activity"
                  fullWidth
                  onChange={(e) => {
                    const newGoals = [...goals.goals];
                    const idx = newGoals.findIndex((x) => x.id === Number(item.id));
                    newGoals[idx].activity = Number(e.target.value);
                    setGoals({ ...goals, goals: newGoals });
                  }}
                >
                  {activities.map((activity, idx) => (
                    <MenuItem key={`idx-${idx}`} value={activity.id}>
                      {activity.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            },
          },
          {
            field: 'duration',
            headerName: 'Duration',
            sortable: false,
            editable: true,
          },
          {
            field: 'frequency',
            headerName: 'Frequency',
            sortable: false,
            editable: true,
          },
          {
            field: 'startDate',
            headerName: 'Start Date',
            sortable: false,
            editable: true,
            renderEditCell: (item) => {
              return (
                <DatePicker
                  value={DateTime.fromISO(item.row.startDate)}
                  onChange={(e) => {
                    const idx = goals.goals.findIndex((x) => x.id === item.id);
                    goals.goals[idx].startDate = e?.toString() || '';
                    setGoals({ ...goals, goals: [...goals.goals] });
                  }}
                ></DatePicker>
              );
            },
          },
          {
            field: 'endDate',
            headerName: 'End Date',
            sortable: false,
            editable: true,
            renderEditCell: (item) => {
              return (
                <DatePicker
                  value={DateTime.fromISO(item.row.endDate)}
                  onChange={(e) => {
                    const idx = goals.goals.findIndex((x) => x.id === item.id);
                    goals.goals[idx].endDate = e?.toString() || '';
                    setGoals({ ...goals, goals: [...goals.goals] });
                  }}
                ></DatePicker>
              );
            },
          },
          {
            field: 'id',
            minWidth: 200,
            headerName: 'Actions',
            sortable: false,
            editable: false,
            renderCell: (item) => {
              const idx = goals.goals.findIndex((x) => x.id === item.row.id);

              if (item.row.id === 0) {
                return (
                  <Grid2 container gap={1}>
                    <Button variant="contained" onClick={() => handleCancelNewGoal(idx)}>
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={(event) => handleCreateGoal(event, idx)}>
                      Save
                    </Button>
                  </Grid2>
                );
              }
              return (
                <Grid2 container gap={1}>
                  <Button variant="contained" onClick={(event) => handleSave(event, item.row.id)}>
                    Save
                  </Button>
                  <Button variant="contained" onClick={(event) => handleDelete(event, item.row.id)}>
                    Delete
                  </Button>
                </Grid2>
              );
            },
          },
        ]}
      ></DataGrid>
      <br />
      <Fab variant={'extended'} onClick={handleAddGoalRow}>
        Add Goal
      </Fab>
    </Box>
  );
};
