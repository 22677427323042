import { PropsWithChildren, useState } from 'react';
import { DialogContent, Grid2, ImageList, ImageListItem, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GroupView } from '../../views/GroupView';
import { CheckBox } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

interface Props extends PropsWithChildren {
  group: GroupView;
  onGroupChange: (e: any) => void;
}

export const GroupDialogContent: React.FC<Props> = ({ children, group, onGroupChange }) => {
  const [roleNameError, setRoleNameError] = useState(false);
  const [roleNameHelperText, setRoleNameHelperText] = useState('');
  const validateRoleName = () => {
    if (group.name.length < 1) {
      setRoleNameError(true);
      setRoleNameHelperText('Role name is required');
    } else {
      setRoleNameError(false);
      setRoleNameHelperText('');
    }
  };

  return (
    <DialogContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        overflow: 'scroll',
      }}
    >
      <TextField
        label={'Group ID'}
        value={group.id}
        disabled={true}
        slotProps={{
          input: {
            readOnly: true,
            hidden: true,
          },
        }}
        fullWidth={true}
        rows={1}
      >
        {group.id}
      </TextField>

      <TextField
        required
        id={'name'}
        onChange={onGroupChange}
        value={group.name}
        label={'Group Name'}
        error={roleNameError}
        onKeyUp={validateRoleName}
        fullWidth={true}
        variant={'outlined'}
        helperText={roleNameHelperText}
      ></TextField>

      <DatePicker label={'Start Date'} key={'startDate'} value={group.startDate} onChange={onGroupChange}></DatePicker>

      <TextField
        id={'shortDescription'}
        onChange={onGroupChange}
        value={group.shortDescription || ''}
        label={'Short Description'}
        multiline
        fullWidth={true}
        rows={4}
        variant={'outlined'}
        content={group.shortDescription}
      ></TextField>

      <TextField
        id={'userCount'}
        onChange={onGroupChange}
        value={group.userCount}
        label={'Expected Number of Participants'}
        type={'number'}
        fullWidth={true}
        rows={4}
        variant={'outlined'}
      ></TextField>

      {children}
    </DialogContent>
  );
};
