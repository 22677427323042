import apiResponse from './ApiResponse';
import { ApiService } from './ApiService';

export class JailCardStore {
  constructor(protected readonly api: ApiService = new ApiService()) {}

  public async Write(cohortUserId: number) {
    try {
      const response = await this.api.post(`admin/CohortUser/${cohortUserId}/jailCards`, {});

      if (response.status < 300) return apiResponse.Success(response);
      else {
        return apiResponse.Error(response, response.statusText);
      }
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

  // We do not currently have an endpoint to return just jail cards, so I'm just grabbing the goals and returning the jail cards from that.
  public async AdminReadByCohortUserId(cohortUserId: number) {
    try {
      const response: any = await this.api.get(`/admin/CohortUser/${cohortUserId}/goals`);
      const jailCards = response.data.availableJailCards;
      return jailCards;
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }
}
