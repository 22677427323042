import React, { useEffect, useState } from 'react';
import { ParticipantView } from '@views';
import { Box, Fab, Typography } from '@mui/material';
import { palette } from '@app/palette';
import { AdminJailCardViewCreator } from '@viewCreators/AdminJailCardViewCreator';
import logger from '@utils/logger';
import { JailCardStore } from '@stores/JailCardStore';
import { ApiResponse } from '@stores';

interface CohortUserJailCardEditorProps {
  cohortUser: ParticipantView;
}

export const CohortUserJailCardEditor: React.FC<CohortUserJailCardEditorProps> = ({ cohortUser }) => {
  const jailCardStore = new JailCardStore();
  const [jailCards, setJailCards] = useState<number>(0);

  // On mount (or when the cohortUser changes) fetch the jail cards for the user
  useEffect(() => {
    const initializeJailCardState = async () => {
      await loadJailCardView();
    };

    initializeJailCardState();
  }, [cohortUser]);

  const loadJailCardView = async () => {
    const view = await new AdminJailCardViewCreator().CreateView(cohortUser.id);
    setJailCards(view.jailCards);
  };

  const handleAddJailCard = async () => {
    if (!window.confirm('Are you sure you want to add a new jail card?')) {
      return;
    }
    try {
      const response: ApiResponse = await jailCardStore.Write(cohortUser.id);
      if (response.status === 'SUCCESS') {
        window.alert('Jail card created successfully');
        loadJailCardView();
      } else {
        window.alert('Failed to create jail card');
        logger.error('Failed to create jail card:', response);
      }
    } catch (error) {
      window.alert('Failed to create jail card');
      logger.error('Error creating jail card:', error);
    }
  };

  return (
    <Box
      borderRadius={8}
      border={1}
      borderColor={palette.background.border}
      display="block"
      position="relative"
      padding={4}
      margin={4}
    >
      <Typography
        variant="subtitle1"
        sx={{
          backgroundColor: palette.background.paper,
          position: 'absolute',
          top: -32,
          left: 48,
          padding: 2,
        }}
      >
        Jail Cards
      </Typography>
      <p>Jail Cards Available: {jailCards}</p>
      <Fab variant={'extended'} onClick={handleAddJailCard}>
        Add Jail Card
      </Fab>
    </Box>
  );
};
