import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { GroupDialogContent } from './GroupDialogContent';
import { ParticipantList } from './ParticipantList';
import { GroupView, ParticipantView } from '@views';
import { ParticipantEditor } from './ParticipantEditor';
import { DebugPanel } from '@components';

export interface EditGroupModalProps extends React.PropsWithChildren {
  open: boolean;
  onClose: (e: React.MouseEvent, reason?: 'SAVE' | string) => void;
  group: GroupView;
  onGroupChange: (e: any) => void;
  cancelModal: () => void;
  participants: ParticipantView[];
  showAddParticipantDialog: boolean;
  onAddParticipant: (email: string) => void;
  handleRemoveParticipant: React.MouseEventHandler<HTMLButtonElement>;
  onShowAddParticipantDialog: React.MouseEventHandler<HTMLButtonElement>;
  onParticipantClose: React.MouseEventHandler<HTMLButtonElement>;
  onSaveGroup: (e: any, reason: string) => void;
  onRoleChanged: (e: any, participant: ParticipantView) => void;
}

export const EditGroupModal: React.FC<EditGroupModalProps> = ({
  cancelModal,
  onGroupChange,
  group,
  open,
  onClose,
  onAddParticipant,
  showAddParticipantDialog,
  participants,
  handleRemoveParticipant,
  onShowAddParticipantDialog,
  onParticipantClose,
  onSaveGroup,
  onRoleChanged,
}) => {
  return (
    <Fragment>
      <Dialog
        maxWidth={'xl'}
        fullWidth={true}
        slotProps={{
          backdrop: { style: { backgroundColor: 'background.paper' } },
        }}
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DebugPanel displayItem={group} />
        <DialogTitle>Edit Cohort Group</DialogTitle>
        <GroupDialogContent group={group} onGroupChange={onGroupChange}>
          <ParticipantList
            length={group.participants.length}
            participants={group.participants}
            editMode={true}
          ></ParticipantList>
        </GroupDialogContent>
        <DialogActions>
          <Button
            onClick={onShowAddParticipantDialog}
            variant={'contained'}
            disableElevation={true}
            color={'secondary'}
            size={'large'}
          >
            Invite Participants
          </Button>

          <Button
            onClick={cancelModal}
            variant={'contained'}
            disableElevation={true}
            color={'secondary'}
            size={'large'}
          >
            Cancel
          </Button>

          <Button onClick={(e) => onSaveGroup(e, 'SAVE')} variant={'contained'} size={'large'}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/*Edit Participants*/}
      <Dialog
        open={showAddParticipantDialog}
        onClose={onClose}
        maxWidth={'xl'}
        fullWidth={true}
        slotProps={{
          backdrop: { style: { backgroundColor: 'background.paper' } },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>Edit Participant List</DialogTitle>

        <ParticipantEditor
          onRoleChange={onRoleChanged}
          userCount={participants.length}
          participantViews={participants}
          onAddParticipant={onAddParticipant}
          onRemoveParticipant={handleRemoveParticipant}
        ></ParticipantEditor>
        <DialogActions>
          <Button onClick={onParticipantClose} variant={'contained'} size={'large'}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
