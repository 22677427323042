import React, { useEffect, useState } from 'react';
import { ParticipantView } from '@views';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  Grid2,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { palette } from '@app/palette';
import Avatar from '@mui/material/Avatar';
import { CohortUserGoalEditor } from './CohortUserGoalEditor';
import { AddActivityDialog } from '../Admin/AddActivityDialog';
import { CohortUserJailCardEditor } from './CohortUserJailCardEditor';
import { CohortUserStore, handleApiResponse } from '@stores';
import logger from '@utils/logger';

interface ParticipantListProps {
  length: number;
  participants: ParticipantView[];
  editMode: boolean;
}

export const ParticipantList: React.FC<ParticipantListProps> = ({ length, participants, editMode }) => {
  const cohortUserStore = new CohortUserStore();
  const [openGoalEditorModal, setOpenGoalEditorModal] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [openJailCardModal, setOpenJailCardModal] = useState(false);
  const [currentParticipants, setCurrentParticipants] = useState<ParticipantView[]>([]);
  const [selectedParticipant, setSelectedParticipant] = useState<ParticipantView | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setCurrentParticipants(participants);
  }, [participants]);

  const handleManageGoalsClick = (participant: ParticipantView) => {
    setSelectedParticipant(participant);
    setOpenGoalEditorModal(true);
  };

  const handleManageJailCardsClick = (participant: ParticipantView) => {
    setSelectedParticipant(participant);
    setOpenJailCardModal(true);
  };

  const handleManageActivityClick = (participant: ParticipantView) => {
    setSelectedParticipant(participant);
    setOpenActivityModal(true);
  };
  // Handler to close the modal.
  const handleCloseModal = () => {
    setOpenGoalEditorModal(false);
    setOpenJailCardModal(false);
    setOpenActivityModal(false);
    setSelectedParticipant(null);
  };

  const handleParticipantActivation = async (participant: ParticipantView) => {
    setSelectedParticipant(participant);
    const cohortId = (await cohortUserStore.readById(participant.CohortUser.id)).result.cohortId;

    try {
      await handleApiResponse(async () => await cohortUserStore.update(participant.CohortUser, cohortId));
      // Update the current participant's isActive status in state
      const updatedParticipants = currentParticipants.map((p) => {
        if (p.email === participant.email) {
          p.CohortUser.isActive = !p.CohortUser.isActive;
        }
        return p;
      });
      setCurrentParticipants(updatedParticipants);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } catch (error) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
      logger.error('Failed to update cohort user:', error);
    }
  };

  return (
    <>
      <Box borderRadius={8} border={1} borderColor={palette.background.border} display={'block'} position={'relative'}>
        <Typography
          variant={'subtitle1'}
          sx={{
            backgroundColor: palette.background.paper,
            position: 'absolute',
            top: -32,
            left: 48,
            padding: 2,
          }}
        >
          Participants ({length})
        </Typography>
        <List>
          {currentParticipants.map((p) => {
            return (
              <ListItem key={p.email}>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: palette.primary.main }}>{p.email[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText>
                  {p.name || p.email}
                  {p.email === selectedParticipant?.email && (
                    <>
                      <Collapse in={showAlert} sx={{ maxWidth: '50%' }}>
                        <Alert severity={'success'} variant={'filled'} sx={{ fontFamily: 'roboto', width: 'auto' }}>
                          Cohort user updated successfully
                        </Alert>
                      </Collapse>
                      <Collapse in={showError} sx={{ maxWidth: '50%' }}>
                        <Alert severity={'error'} variant={'filled'} sx={{ fontFamily: 'roboto' }}>
                          Failed to update cohort user
                        </Alert>
                      </Collapse>
                    </>
                  )}
                  {editMode && (
                    <div>
                      <br />
                      <Grid2 container gap={1}>
                        <Button variant={'outlined'} onClick={() => handleManageGoalsClick(p)}>
                          Manage goals
                        </Button>
                        <Button variant={'outlined'} onClick={() => handleManageActivityClick(p)}>
                          Manage Activity
                        </Button>
                        <Button variant={'outlined'} onClick={() => handleManageJailCardsClick(p)}>
                          Manage Jail Cards
                        </Button>
                        <Button variant={'outlined'} onClick={() => handleParticipantActivation(p)}>
                          {p.CohortUser.isActive ? 'Deactivate Participant' : 'Activate Participant'}
                        </Button>
                      </Grid2>
                    </div>
                  )}
                </ListItemText>
                <ListItemSecondaryAction>{p.role}</ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Box>

      {/* Add activity modal */}
      {selectedParticipant && (
        <AddActivityDialog
          open={openActivityModal}
          onClose={handleCloseModal}
          participant={selectedParticipant}
        ></AddActivityDialog>
      )}

      {/* Goals modal */}
      <Dialog
        open={openGoalEditorModal}
        onClose={handleCloseModal}
        maxWidth="xl"
        fullWidth={true}
        slotProps={{
          backdrop: { style: { backgroundColor: 'background.paper' } },
        }}
      >
        {selectedParticipant && <CohortUserGoalEditor cohortUser={selectedParticipant} />}
      </Dialog>

      {/* Jail cards modal */}
      <Dialog
        open={openJailCardModal}
        onClose={handleCloseModal}
        maxWidth="xl"
        fullWidth={true}
        slotProps={{
          backdrop: { style: { backgroundColor: 'background.paper' } },
        }}
      >
        {selectedParticipant && <CohortUserJailCardEditor cohortUser={selectedParticipant} />}
      </Dialog>
    </>
  );
};
